import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import mitt from 'mitt';
import AOS from 'aos';
import 'aos/dist/aos.css';

const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.$emitter = emitter;

AOS.init();

loadFonts().then();

app.use(router)
    .use(vuetify)
    .mount('#app');
