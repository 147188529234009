import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/SiteLayout.vue'),
        children: [
            {path: '', name: 'home', component: () => import(/* webpackChunkName: "main-view" */ '../views/MainView.vue')}
        ]
    },
    {
        path: '/home',
        redirect: '/',
    },
    {
        path: '/about',
        component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/SiteLayout.vue'),
        children: [
            {path: '', name: 'about', component: () => import(/* webpackChunkName: "main-view" */ '../views/AboutView.vue')}
        ]
    },
    {
        path: '/cart',
        component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/SiteLayout.vue'),
        children: [
            {path: '', name: 'cart', component: () => import(/* webpackChunkName: "main-view" */ '../views/CartView.vue')}
        ]
    },
    {
        path: '/cabinet',
        component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/CabinetLayout.vue'),
        children: [
            {path: '', name: 'cabinet', component: () => import(/* webpackChunkName: "main-view" */ '../views/CabinetView.vue')}
        ]
    },
    {
        path: '/favorites',
        component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/CabinetLayout.vue'),
        children: [
            {path: '', name: 'favorites', component: () => import(/* webpackChunkName: "main-view" */ '../views/FavoritesView.vue')}
        ]
    },
    {
        path: '/billing',
        component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/CabinetLayout.vue'),
        children: [
            {path: '', name: 'billing', component: () => import(/* webpackChunkName: "main-view" */ '../views/BillingView.vue')}
        ]
    },
    {
        path: '/tickets',
        component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/CabinetLayout.vue'),
        children: [
            {path: '', name: 'tickets', component: () => import(/* webpackChunkName: "main-view" */ '../views/TicketsView.vue')}
        ]
    },
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/SiteLayout.vue'),
        children: [
            {path: '', name: 'login', component: () => import(/* webpackChunkName: "main-view" */ '../views/LoginView.vue')}
        ]
    },
    {
        path: '/weather',
        component: () => import(/* webpackChunkName: "default-layout" */ '../views/WeatherView.vue'),
        // component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/SiteLayout.vue'),
        // children: [
        //     {path: '', name: 'weather', component: () => import(/* webpackChunkName: "main-view" */ '../views/WeatherView.vue')}
        // ]
    },
    {
        path: '/table',
        component: () => import(/* webpackChunkName: "default-layout" */ '../views/TableView.vue'),
    },
    // {
    //     path: '/:pathMatch(.*)*', // Для обработки всех неизвестных путей
    //     component: () => import(/* webpackChunkName: "default-layout" */ '../layouts/SiteLayout.vue'),
    //     children: [
    //         {
    //             path: '',
    //             name: 'home',
    //             component: () => import(/* webpackChunkName: "main-view" */ '../views/NotFund.vue')
    //         }
    //     ]
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
