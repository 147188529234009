<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default ({
  name: 'App',
  components: {},

})

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.v-container {
  width: 100% !important;
  max-width: 1400px !important;
}

</style>